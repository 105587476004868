import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="container py-5 text-center">
        <h1 className="display-5">The page you're looking for doesn't exist</h1>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
